$light-grey: #f0f0f0;

.main-header .breadcrumb {
  font-size: 0.8em;
}

.old-public-styles.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain);
}

.custom-link-button {
  border-width: 2px;
}

.text-orange {
  color: #f47b21;
}

.overlay {
  background: rgba(0,0,0,.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.text-light-grey {
  color: $light-grey;
}
.main-footer.text-light-grey a:hover {
  color: $light-grey;
}

i.fa-2x.tour-state {
  font-size: 1.4em;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.form-check-input:disabled ~ .form-check-label {
  color: #e0e0e0;
}

.price-disabled {
  color: #e0e0e0;
}

.instructor-profile-img {
  min-width: 6rem;
  max-width: 6rem;
  min-height: 8rem;
  max-height: 8rem;
  object-fit: cover;
}
