// Fonts

// bitter-regular - latin
@font-face {
  font-display: swap;
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/bitter/bitter-v32-latin-regular.eot'); // IE9 Compat Modes
  src: url('../../fonts/bitter/bitter-v32-latin-regular.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('../../fonts/bitter/bitter-v32-latin-regular.woff2') format('woff2'), // Super Modern Browsers
  url('../../fonts/bitter/bitter-v32-latin-regular.woff') format('woff'), // Modern Browsers
  url('../../fonts/bitter/bitter-v32-latin-regular.ttf') format('truetype'), // Safari, Android, iOS
  url('../../fonts/bitter/bitter-v32-latin-regular.svg#Bitter') format('svg'); // Legacy iOS
}

// bitter-700 - latin
@font-face {
  font-display: swap;
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/bitter/bitter-v32-latin-700.eot'); // IE9 Compat Modes
  src: url('../../fonts/bitter/bitter-v32-latin-700.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('../../fonts/bitter/bitter-v32-latin-700.woff2') format('woff2'), // Super Modern Browsers
  url('../../fonts/bitter/bitter-v32-latin-700.woff') format('woff'), // Modern Browsers
  url('../../fonts/bitter/bitter-v32-latin-700.ttf') format('truetype'), // Safari, Android, iOS
  url('../../fonts/bitter/bitter-v32-latin-700.svg#Bitter') format('svg'); // Legacy iOS
}

// lato-100 - latin
@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('../../fonts/lato/lato-v23-latin-100.eot'); // IE9 Compat Modes
  src: url('../../fonts/lato/lato-v23-latin-100.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('../../fonts/lato/lato-v23-latin-100.woff2') format('woff2'), // Super Modern Browsers
  url('../../fonts/lato/lato-v23-latin-100.woff') format('woff'), // Modern Browsers
  url('../../fonts/lato/lato-v23-latin-100.ttf') format('truetype'), // Safari, Android, iOS
  url('../../fonts/lato/lato-v23-latin-100.svg#Lato') format('svg'); // Legacy iOS
}

// lato-300 - latin
@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/lato/lato-v23-latin-300.eot'); // IE9 Compat Modes
  src: url('../../fonts/lato/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('../../fonts/lato/lato-v23-latin-300.woff2') format('woff2'), // Super Modern Browsers
  url('../../fonts/lato/lato-v23-latin-300.woff') format('woff'), // Modern Browsers
  url('../../fonts/lato/lato-v23-latin-300.ttf') format('truetype'), // Safari, Android, iOS
  url('../../fonts/lato/lato-v23-latin-300.svg#Lato') format('svg'); // Legacy iOS
}

// lato-regular - latin
@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/lato/lato-v23-latin-regular.eot'); // IE9 Compat Modes
  src: url('../../fonts/lato/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('../../fonts/lato/lato-v23-latin-regular.woff2') format('woff2'), // Super Modern Browsers
  url('../../fonts/lato/lato-v23-latin-regular.woff') format('woff'), // Modern Browsers
  url('../../fonts/lato/lato-v23-latin-regular.ttf') format('truetype'), // Safari, Android, iOS
  url('../../fonts/lato/lato-v23-latin-regular.svg#Lato') format('svg'); // Legacy iOS
}

// lato-700 - latin
@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/lato/lato-v23-latin-700.eot'); // IE9 Compat Modes
  src: url('../../fonts/lato/lato-v23-latin-700.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('../../fonts/lato/lato-v23-latin-700.woff2') format('woff2'), // Super Modern Browsers
  url('../../fonts/lato/lato-v23-latin-700.woff') format('woff'), // Modern Browsers
  url('../../fonts/lato/lato-v23-latin-700.ttf') format('truetype'), // Safari, Android, iOS
  url('../../fonts/lato/lato-v23-latin-700.svg#Lato') format('svg'); // Legacy iOS
}

// Colors
$white:    #fff !default;
$custom-grey: #2b2b2b;
$primary:      #007bff !default;
$navbar-light-hover-color: $primary !default;
$navbar-light-active-color: $primary !default;

$breadcrumb-divider:  quote(">");
$font-family-base: 'Lato', sans-serif;

$enable-rounded:              false;

// Links
$link-hover-decoration:       none;
$link-hover-color:            $custom-grey;

// Headings h1, h2, ...
$headings-font-family:        'Bitter', serif;
$headings-font-weight:        400;
$headings-color:              $custom-grey;

$font-weight-light:           100;
$font-weight-normal:          300;
$font-weight-bold:            700;
$font-weight-bolder:          700;

$line-height-base:            1.6;

// Input
$input-color:                 #555;
$input-font-size:             0.9rem;
$input-line-height:           1.3;
$input-btn-focus-box-shadow:  none;
$input-focus-border-color:    $primary;

$body-color: #777;

// Navigation
$nav-link-padding-y:                .45rem;
$nav-link-padding-x:                1.7rem;
$nav-tabs-link-active-border-color: $primary;
$nav-tabs-link-hover-border-color: $white;
$nav-tabs-link-active-color: $primary;

@import "../vendor/bootstrap/bootstrap";

// CSS

body {
  text-rendering: optimizeLegibility;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs > li > a {
  color: $custom-grey;
}

.nav-tabs > li > a:hover {
  color: $primary;
}

label {
  font-weight: 700;
}

.btn-outline-primary:hover, .btn-outline-primary:focus {
  color: $white;
}

.btn-outline-primary:focus {
  background-color: $primary;
}

.highlighted-image-1 {
  height: 500px;
}

.bg-image-start {
  background: url('/req/images/navigation/welcome.jpg') scroll center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.custom-main-menu.navbar {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.custom-main-menu .nav-item {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.custom-main-menu .dropdown-menu {
  margin-top: 0rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.custom-main-menu .dropdown-item {
  padding: .75rem 1.25rem;
  color: $body-color;
  line-height: 1.25rem;
}

.custom-main-menu .nav-link {
  color: $body-color;
}

.custom-main-menu .dropdown-item:hover,
.custom-main-menu .dropdown-item:focus {
  color: $primary;
  background-color: #f6f6f6;
}

.custom-main-menu .nav-item:not(:first-child) {
  border-top: 1px dotted #cdcdcd;
}

.custom-main-menu .navbar-nav {
  margin-left: auto;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  #navbarSupportedContent {
    margin-top: 3rem;
  }

  .custom-main-menu.navbar .nav-item .dropdown-menu { display: none; }
  .custom-main-menu.navbar .nav-item:hover .dropdown-menu { display: block; }

  .custom-main-menu .nav-item:not(:first-child) {
    border: 0;
  }

  .custom-main-menu .nav-item {
    border: 0;
  }

  .custom-main-menu .dropdown-item:hover,
  .custom-main-menu .dropdown-item:focus {
    background-color: #2b2b2b;
  }

  .custom-main-menu .dropdown-menu {
    background-color: #222;
  }

  .custom-main-menu .dropdown-item {
    color: #cdcdcd;
  }

  .custom-main-menu .nav-link {
    padding-bottom: 2.3rem;
  }
}
/* ============ desktop view .end// ============ */

@import "../general";
